<!--
 * @Description: 资源库
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-21 20:14:17
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/resourceLibrary/index.vue
-->
<template>
  <div v-if="product">
    <!-- v-if="
          product?.isMustChooseAiPiecesVersion ||
          product?.isAllowedChooseAiPiecesVersion
        "
       -->
    <Shear
      :product="product"
      v-if="!product?.ai_clip_version || switchState"
      @update="
        () => {
          getInfo();
          switchState = false;
        }
      "
    />
    <QuickShear1
      :product="product"
      v-if="product?.ai_clip_version === 'p_v1.0' && !switchState"
      @on-upgrade="handleUpgrade"
      @on-switch="switchState = true"
    />
    <Speciality
      :product="product"
      v-if="product?.ai_clip_version === 'p_v2.0' && !switchState"
      @on-upgrade="handleUpgrade"
      @on-switch="switchState = true"
    />
    <FinalCut
      :product="product"
      v-if="
        ['c_v1.0', 'c_v2.0'].includes(product?.ai_clip_version) && !switchState
      "
      @on-upgrade="handleUpgrade"
      @on-switch="switchState = true"
    />

    <!-- 升级套餐 -->
    <EscalateView
      v-if="escalate.show"
      :show.sync="escalate.show"
      v-bind="escalate"
      @update="getInfo"
    />
  </div>
</template>

<script>
import Shear from "./components/shear.vue";
import QuickShear1 from "./components/quickShear1.vue";
import Speciality from "./components/speciality.vue";
import FinalCut from "./components/finalCut.vue";
import { HTTP } from "../../../../utils/request";
import EscalateView from "@/pages/views/user/order/mods/escalate.vue";

const URL = {
  info: "api/customer/user/info",
};

export default {
  components: {
    Shear,
    QuickShear1,
    Speciality,
    FinalCut,
    EscalateView,
  },
  data() {
    return {
      product: null,
      switchState: false,
      escalate: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    async getInfo() {
      const { product } = await HTTP({
        url: URL.info,
        method: "get",
      });
      this.product = product;
    },
    handleUpgrade(data) {
      this.escalate = {
        show: true,
        data,
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
