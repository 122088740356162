<!--
 * @Description: 素材详情
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-28 21:47:01
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/resourceLibrary/mods/detail.vue
-->
<template>
  <div class="model-popup" @click="shows = false">
    <div class="model" @click.stop>
      <div class="model-text">素材详情</div>
      <div class="model-table">
        <div
          class="source-material-list"
          v-if="tableData.length"
          v-loading="isLoading"
        >
          <div
            class="source-material-item"
            v-for="item in tableData"
            :key="item.id"
          >
            <div class="item-content">
              <img
                :src="item.type === 4 ? item.video_cover_path : item.filepath"
                alt=""
              />
              <div
                class="content-img"
                @click="
                  () => {
                    video = {
                      show: true,
                      data: {
                        content: item.filepath,
                      },
                    };
                  }
                "
                v-if="item.type === 4"
              >
                <img
                  src="../../../../../assets/icon/play.png"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
        <el-empty v-else description="暂无上传"></el-empty>
        <pagination
          v-show="page.total > 0"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.size"
          @pagination="getList()"
        />
      </div>
      <div class="model-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
    <VideoView v-if="video.show" :show.sync="video.show" v-bind="video" />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";
import VideoView from "@/pages/views/custom/examine/components/video.vue";

const URL = {
  list: "api/customer/userMaterial/historyDetail",
};

export default {
  components: {
    VideoView,
  },
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },

  data() {
    return {
      tableData: [],
      video: {
        show: false,
        data: null,
      },
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
          materialVersionId: this.data?.id,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    handleOpts(type, row = null) {
      switch (type) {
        case "see":
          console.log(row);
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.model-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  .model {
    width: 910px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-table {
      padding: 20px;
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.source-material-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-left: 1px solid #e4e8fc;
  margin-top: 14px;
  height: 520px;
  overflow-y: auto;
  .source-material-item {
    width: calc(100% / 5);
    padding: 20px;
    border-right: 1px solid #e4e8fc;
    border-bottom: 1px solid #e4e8fc;
    border-top: 1px solid #e4e8fc;
    .item-content {
      width: 126px;
      height: 222px;
      background-color: #eee;
      margin: 0 auto;
      position: relative;
      & > img {
        width: 100%;
        height: 100%;
      }
      .content-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
    }
    .item-remove {
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #7c7f97;
      margin-top: 20px;
      &:hover {
        color: #4e6bef;
        cursor: pointer;
      }
    }
    &:hover {
      background-color: #f6f6fd;
    }
  }
}
</style>
