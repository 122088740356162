<!--
 * @Description: 资源库选择
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-21 21:08:16
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/resourceLibrary/components/shear.vue
-->
<template>
  <div class="app-wrapper" style="overflow: hidden">
    <div class="wrapper-view">
      <div class="wrapper-title">资源库</div>
    </div>
    <div class="wrapper-text">
      <template>
        您当前套餐所提供的剪辑服务为：<span>{{
          product?.ai_clip_version_title
        }}</span>
      </template>

      <template v-if="product?.isMustChooseAiPiecesVersion">
        请选择快剪版本：
        <el-radio v-model="radio" label="1"
          >快剪1.0（系统提供图片，视频展示简单）</el-radio
        >
        <el-radio v-model="radio" label="2"
          >快剪2.0（需提供公司、产品细节图片，视频效果更佳）</el-radio
        >
      </template>
    </div>
    <Shear1 v-if="radio === '1'" />
    <Shear2 v-else-if="radio === '2'" />
    <div
      class="shear-btn"
      v-if="
        product?.isMustChooseAiPiecesVersion ||
        product?.isAllowedChooseAiPiecesVersion
      "
    >
      <el-button type="primary" @click="handleConfirm">确认选择</el-button>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";
import Shear1 from "./shear1.vue";
import Shear2 from "./shear2.vue";

const URL = {
  chooseAiPiecesVersion: "api/customer/userProduct/chooseAiPiecesVersion",
};

export default {
  components: {
    Shear1,
    Shear2,
  },
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      radio: "1",
    };
  },
  methods: {
    handleConfirm() {
      this.$confirm(
        `请确认是否选择“快剪${
          this.radio === "1" ? "1.0" : "2.0"
        }”，版本切换仅支持每月调整一次`,
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.chooseAiPiecesVersion();
        })
        .catch(() => {});
    },

    async chooseAiPiecesVersion() {
      try {
        await HTTP({
          url: URL.chooseAiPiecesVersion,
          method: "post",
          data: {
            aiPiecesVersion:
              this.radio === "1"
                ? "p_v1.0"
                : this.radio === "2"
                ? "p_v2.0"
                : "",
          },
        });
        this.$emit("update");
      } catch (e) {
        console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-text {
  padding: 0 26px 16px 26px;
  font-size: 14px;
  span {
    font-weight: bold;
    padding-right: 10px;
  }
}
.shear-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px;
}
</style>
