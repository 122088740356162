import { render, staticRenderFns } from "./shear1.vue?vue&type=template&id=2cb6ee56&scoped=true&"
import script from "./shear1.vue?vue&type=script&lang=js&"
export * from "./shear1.vue?vue&type=script&lang=js&"
import style0 from "./shear1.vue?vue&type=style&index=0&id=2cb6ee56&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cb6ee56",
  null
  
)

export default component.exports