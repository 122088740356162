<!--
 * @Description: 升级
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-19 23:52:20
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/user/order/mods/escalate.vue
-->
<template>
  <div class="negative-popup">
    <div class="negative" @click.stop>
      <div class="negative-text">选择升级的套餐</div>
      <div class="negative-content">
        <div class="escalate-list" v-if="list.length">
          <div class="escalate-item" v-for="item in list" :key="item.id">
            <div
              class="escalate-item-classify escalate"
              :style="`backgroundImage: url(${item.icon_path})`"
            >
              {{ item.title }}
            </div>
            <div class="escalate-item-select">
              <el-radio v-model="radio" :label="item.id">选择</el-radio>
            </div>
          </div>
        </div>
      </div>
      <div class="negative-btn" v-if="list.length">
        <div class="btn confirm" @click="handleConfirm">确认</div>
        <div class="btn" @click="shows = false">取消</div>
      </div>
      <div v-else style="margin-bottom: 40px">
        <el-empty description="您当前已是最高套餐"></el-empty>
      </div>
      <div class="negative-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";

const URL = {
  upgradeSelect: "api/customer/userProduct/upgradeSelect",
  applyUpgrade: "api/customer/userProduct/applyUpgrade",
};
export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  data() {
    return {
      radio: null,
      list: [],
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      const res = await HTTP({
        url: URL.upgradeSelect,
        method: "get",
        data: {
          orderno: this.data.orderno,
        },
      });
      this.list = res || [];
    },
    async handleConfirm() {
      if (!this.radio) {
        this.$message.error("请选择升级的套餐");
        return;
      }
      await HTTP({
        url: URL.applyUpgrade,
        method: "post",
        data: {
          orderno: this.data.orderno,
          newProductId: this.radio,
        },
      });
      this.$message.success("操作成功");
      this.shows = false;
      this.$emit("update");
    },
  },
};
</script>

<style lang="scss" scoped>
.negative-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  .negative {
    width: calc(372px + 247px);
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-content {
      display: flex;
      justify-content: center;
      justify-content: center;
      margin-top: 45px;
      .escalate-list {
        display: flex;
        align-items: center;
        justify-content: center;
        .escalate-item {
          width: 125px;
          text-align: center;
          margin-right: 44px;
          &:last-child {
            margin-right: 0;
          }
          .escalate-item-classify {
            width: 125px;
            height: 154px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            font-family: HYLiLiangHeiJ-Regular, HYLiLiangHeiJ;
            font-weight: 400;
            color: #ffffff;
            line-height: 24px;
            text-shadow: 0px 2px 4px #85abd6;
          }
          .escalate {
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            text-align: center;
          }
          .escalate-item-select {
            margin-top: 15px;
          }
        }
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 45px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 0;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
