var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"model-popup",on:{"click":function($event){_vm.shows = false}}},[_c('div',{staticClass:"model",on:{"click":function($event){$event.stopPropagation();}}},[_c('div',{staticClass:"model-text"},[_vm._v("素材详情")]),_c('div',{staticClass:"model-table"},[(_vm.tableData.length)?_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"source-material-list"},_vm._l((_vm.tableData),function(item){return _c('div',{key:item.id,staticClass:"source-material-item"},[_c('div',{staticClass:"item-content"},[_c('img',{attrs:{"src":item.type === 4 ? item.video_cover_path : item.filepath,"alt":""}}),(item.type === 4)?_c('div',{staticClass:"content-img",on:{"click":() => {
                  _vm.video = {
                    show: true,
                    data: {
                      content: item.filepath,
                    },
                  };
                }}},[_c('img',{attrs:{"src":require("../../../../../assets/icon/play.png"),"alt":"","srcset":""}})]):_vm._e()])])}),0):_c('el-empty',{attrs:{"description":"暂无上传"}}),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.page.total > 0),expression:"page.total > 0"}],attrs:{"total":_vm.page.total,"page":_vm.page.page,"limit":_vm.page.size},on:{"update:page":function($event){return _vm.$set(_vm.page, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.page, "size", $event)},"pagination":function($event){return _vm.getList()}}})],1),_c('div',{staticClass:"model-close",on:{"click":function($event){_vm.shows = false}}},[_c('img',{attrs:{"src":require("../../../../../assets/icon/close.png"),"alt":""}})])]),(_vm.video.show)?_c('VideoView',_vm._b({attrs:{"show":_vm.video.show},on:{"update:show":function($event){return _vm.$set(_vm.video, "show", $event)}}},'VideoView',_vm.video,false)):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }