<!--
 * @Description: 快剪1.0
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-21 20:40:32
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/resourceLibrary/components/shear1.vue
-->
<template>
  <div class="wrapper-view">
    <div class="wrapper-text">版本说明</div>
    <div class="wrapper-span">
      快剪1.0适用于各行各业，无需上传其他任何素材，然而视频展示相对简单；若您希望更好地展示公司的产品和实力，我们建议您选择专业版的
    </div>
    <div class="video-view">
      <div class="wrapper-text">视频模板示例</div>
      <div class="video-list">
        <div
          class="video-item"
          v-for="(item, index) in videoExampleList"
          :key="index"
        >
          <div class="video-item-bg" @click="handleVideoChange(item)">
            <img :src="item.cover" alt="" />
            <div class="content-img">
              <img src="../../../../../assets/icon/play.png" alt="" srcset="" />
            </div>
          </div>
          <div class="video-item-cells">
            <div class="cell">
              <div class="cell-label">适用行业</div>
              <div>：</div>
              <div class="cell-value">{{ item.industry }}</div>
            </div>
            <div class="cell">
              <div class="cell-label">视频属性</div>
              <div>：</div>
              <div class="cell-value">{{ item.attribute }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <VideoView v-if="video.show" :show.sync="video.show" v-bind="video" />
  </div>
</template>

<script>
import VideoView from "@/pages/views/custom/examine/components/video.vue";
import { HTTP } from "../../../../../utils/request";

const URL = {
  videoExample: "api/common/config/videoExample",
};

export default {
  components: {
    VideoView,
  },
  data() {
    return {
      videoExampleList: [],
      video: {
        show: false,
        data: null,
      },
    };
  },
  mounted() {
    this.getVideoExample();
  },
  methods: {
    async getVideoExample() {
      const res = await HTTP({
        url: URL.videoExample,
        method: "get",
        data: {
          aiClipVersion: "p_v1.0",
        },
      });
      this.videoExampleList = res || [];
    },
    handleVideoChange(item) {
      this.video = {
        show: true,
        data: {
          content: item.url,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-text {
  font-size: 17px;
  color: #2f3356;
  font-weight: bold;
}
.wrapper-span {
  font-size: 15px;
  margin-top: 10px;
}
.video-view {
  margin-top: 37px;
  .video-list {
    display: flex;
    height: 140px;
    .video-item {
      flex: 1;
      display: flex;
      margin-top: 24px;
      &-bg {
        width: 70px;
        height: 114px;
        // background: #bfbfbf;
        margin-right: 22px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .content-img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: rgba($color: #000000, $alpha: 0.3);
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 30px;
            height: 30px;
            cursor: pointer;
          }
        }
      }
      &-cells {
        flex: 1;
        .cell {
          display: flex;
          color: #7c7f97;
          font-size: 14px;
          margin-bottom: 8px;
          .cell-label {
            width: 60px;
            text-align: justify;
            text-align-last: justify;
          }
          .cell-value {
            flex: 1;
            font-size: 14px;
            font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
            font-weight: normal;
            color: #4e4e4e;
            margin-right: 20px;
          }
        }
      }
    }
  }
}
</style>
