var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"source-material"},[_c('div',{staticClass:"content-title"},[_vm._v(" 素材上传 "),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"说明：为确保AI剪辑效果不受影响，请每90天更新一次素材。在提交审核之前，请务必上传完整的素材。","placement":"top-start"}},[_c('i',{staticClass:"el-icon-info"})]),_c('el-link',{staticStyle:{"margin-left":"20px"},attrs:{"type":"primary"},on:{"click":function($event){_vm.log.show = true}}},[_vm._v("历史素材")])],1),_c('div',{staticClass:"content-head"},[_c('div',{staticClass:"head-btn"},[(_vm.activeIndex === 2)?_c('el-button',{attrs:{"type":"primary","size":"medium","disabled":_vm.product?.isDisableMaterisUploadEntry},on:{"click":function($event){_vm.popupState = true}}},[_vm._v("上传图片")]):(_vm.activeIndex === 3)?_c('el-button',{attrs:{"type":"primary","size":"medium","disabled":_vm.product?.isDisableMaterisUploadEntry},on:{"click":function($event){_vm.popupState = true}}},[_vm._v("上传视频")]):_vm._e(),(_vm.activeIndex !== 0)?_c('el-button',{attrs:{"type":"primary","size":"medium","disabled":_vm.product?.isDisableMaterisUploadEntry},on:{"click":_vm.handleSubmit}},[_vm._v("提交审核")]):_vm._e()],1),_c('div',{staticClass:"head-tabs"},[(_vm.product?.materisUploadPower?.includes('image'))?_c('div',{class:['tab-item', { active: _vm.activeIndex === 2 }],on:{"click":function($event){_vm.activeIndex = 2}}},[_vm._v(" 图片 ")]):_vm._e(),(_vm.product?.materisUploadPower?.includes('video'))?_c('div',{class:['tab-item', { active: _vm.activeIndex === 3 }],on:{"click":function($event){_vm.activeIndex = 3}}},[_vm._v(" 视频 ")]):_vm._e()])]),(_vm.activeIndex === 2 && _vm.product?.materisUploadPower?.includes('image'))?_c('images-view',{ref:"images",attrs:{"product":_vm.product}}):_vm._e(),(
      _vm.activeIndex === 2 &&
      _vm.popupState &&
      _vm.product?.materisUploadPower?.includes('image')
    )?_c('image-add-popup',{attrs:{"show":_vm.popupState},on:{"update:show":function($event){_vm.popupState=$event},"update":_vm.getRefresh}}):(
      _vm.activeIndex === 3 && _vm.product?.materisUploadPower?.includes('video')
    )?_c('videos-view',{ref:"videos",attrs:{"product":_vm.product}}):_vm._e(),(
      _vm.activeIndex === 3 &&
      _vm.popupState &&
      _vm.product?.materisUploadPower?.includes('video')
    )?_c('video-add-popup',{attrs:{"show":_vm.popupState},on:{"update:show":function($event){_vm.popupState=$event},"update":_vm.getRefresh}}):_vm._e(),(_vm.log.show)?_c('LogView',{attrs:{"show":_vm.log.show},on:{"update:show":function($event){return _vm.$set(_vm.log, "show", $event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }