<!--
 * @Description: 素材上传
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-21 21:51:30
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/resourceLibrary/components/sourceMaterial.vue
-->
<template>
  <div class="source-material">
    <div class="content-title">
      素材上传
      <el-tooltip
        class="item"
        effect="dark"
        content="说明：为确保AI剪辑效果不受影响，请每90天更新一次素材。在提交审核之前，请务必上传完整的素材。"
        placement="top-start"
      >
        <i class="el-icon-info"></i>
      </el-tooltip>
      <el-link type="primary" style="margin-left: 20px" @click="log.show = true"
        >历史素材</el-link
      >
    </div>
    <div class="content-head">
      <div class="head-btn">
        <el-button
          type="primary"
          size="medium"
          v-if="activeIndex === 2"
          :disabled="product?.isDisableMaterisUploadEntry"
          @click="popupState = true"
          >上传图片</el-button
        >
        <el-button
          type="primary"
          size="medium"
          v-else-if="activeIndex === 3"
          :disabled="product?.isDisableMaterisUploadEntry"
          @click="popupState = true"
          >上传视频</el-button
        >
        <el-button
          type="primary"
          size="medium"
          v-if="activeIndex !== 0"
          :disabled="product?.isDisableMaterisUploadEntry"
          @click="handleSubmit"
          >提交审核</el-button
        >
      </div>
      <div class="head-tabs">
        <div
          v-if="product?.materisUploadPower?.includes('image')"
          :class="['tab-item', { active: activeIndex === 2 }]"
          @click="activeIndex = 2"
        >
          图片
        </div>
        <div
          v-if="product?.materisUploadPower?.includes('video')"
          :class="['tab-item', { active: activeIndex === 3 }]"
          @click="activeIndex = 3"
        >
          视频
        </div>
      </div>
    </div>
    <!-- 图片 -->
    <images-view
      ref="images"
      v-if="activeIndex === 2 && product?.materisUploadPower?.includes('image')"
      :product="product"
    ></images-view>
    <image-add-popup
      v-if="
        activeIndex === 2 &&
        popupState &&
        product?.materisUploadPower?.includes('image')
      "
      :show.sync="popupState"
      @update="getRefresh"
    ></image-add-popup>

    <!-- 视频 -->
    <videos-view
      ref="videos"
      v-else-if="
        activeIndex === 3 && product?.materisUploadPower?.includes('video')
      "
      :product="product"
    ></videos-view>
    <video-add-popup
      v-if="
        activeIndex === 3 &&
        popupState &&
        product?.materisUploadPower?.includes('video')
      "
      :show.sync="popupState"
      @update="getRefresh"
    ></video-add-popup>

    <!-- 历史素材 -->
    <LogView v-if="log.show" :show.sync="log.show" />
  </div>
</template>

<script>
import ImagesView from "../../sourceMaterial/mods/images.vue";
import ImageAddPopup from "../../sourceMaterial/components/imageAdd.vue";

import VideosView from "../../sourceMaterial/mods/videos.vue";
import VideoAddPopup from "../../sourceMaterial/components/videoAdd.vue";

import LogView from "../mods/log.vue";
import { HTTP } from "../../../../../utils/request";

const URL = {
  audit: "api/customer/userMaterial/audit",
};

export default {
  components: {
    ImagesView,
    ImageAddPopup,

    VideosView,
    VideoAddPopup,

    LogView,
  },
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      activeIndex: this.product?.materisUploadPower?.includes("image")
        ? 2
        : this.product?.materisUploadPower?.includes("video")
        ? 3
        : null,
      popupState: false,
      log: {
        show: false,
      },
    };
  },
  methods: {
    getRefresh() {
      const ref =
        this.$refs?.negative ||
        this.$refs?.coreWords ||
        this.$refs?.images ||
        this.$refs?.videos;
      ref?.getList(1);
    },
    // 提交
    async handleSubmit() {
      await HTTP({
        url: URL.audit,
        method: "post",
        data: {
          materialType: this.activeIndex + 1,
        },
      });
      this.$message.success("操作成功");
      this.getRefresh();
    },
  },
};
</script>

<style lang="scss" scoped>
.source-material {
  padding: 16px 26px;
  .content-title {
    font-size: 17px;
    font-family: SourceHanSansCN-Bold-, SourceHanSansCN-Bold;
    font-weight: bold;
    color: #2f3356;
    line-height: 29px;
  }
  .content-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
    .head-btn {
      display: flex;
      align-items: center;
      .btn {
        width: 128px;
        height: 33px;
        background: #4e6bef;
        margin-right: 12px;
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
    }
    .head-tabs {
      display: flex;
      align-items: center;
      .tab-item {
        width: 76px;
        height: 33px;
        background: #f6f9ff;
        border: 1px solid #bec3cb;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #353535;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
        margin-left: 12px;
        position: relative;
      }
      .active {
        border: 1px solid #4586f0;
        &::before {
          content: "";
          position: absolute;
          width: 18px;
          height: 17px;
          display: inline-block;
          background: url("../../../../../assets/icon/active.png");
          background-size: cover;
          right: 0px;
          bottom: 0px;
        }
      }
    }
  }
}
</style>
