<!--
 * @Description: 历史素材
 * 
 * @Author: xiongyy<60009684>
 * @Date: 2023-11-28 21:19:45
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/resourceLibrary/mods/log.vue
-->
<template>
  <div class="model-popup" @click="shows = false">
    <div class="model" @click.stop>
      <div class="model-text">历史素材</div>
      <div class="model-table">
        <el-table
          :data="tableData"
          stripe
          max-height="616"
          style="width: 100%"
          v-loading="isLoading"
        >
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="55"
          ></el-table-column>
          <el-table-column
            prop="created_at"
            show-overflow-tooltip
            label="日期"
            align="center"
          >
          </el-table-column>
          <el-table-column
            prop="opt"
            show-overflow-tooltip
            label="操作"
            align="center"
            width="200"
          >
            <template slot-scope="{ row }">
              <el-button size="mini" type="text" @click="handleOpts('see', row)"
                >查看素材</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination
          v-show="page.total > 0"
          :total="page.total"
          :page.sync="page.page"
          :limit.sync="page.size"
          @pagination="getList()"
        />
      </div>
      <div class="model-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>

    <DetailView v-if="detail.show" :show.sync="detail.show" v-bind="detail" />
  </div>
</template>

<script>
import DetailView from "./detail.vue";
import { HTTP } from "../../../../../utils/request";

const URL = {
  list: "api/customer/userMaterial/historyList",
};

export default {
  components: {
    DetailView,
  },
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },

  data() {
    return {
      tableData: [],
      detail: {
        show: false,
        data: null,
      },
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          page,
          limit: size,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    handleOpts(type, row = null) {
      switch (type) {
        case "see":
          this.detail = {
            show: true,
            data: {
              ...row,
            },
          };
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.model-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  z-index: 100;
  align-items: center;
  justify-content: center;
  .model {
    width: 810px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-table {
      padding: 20px;
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
